import { map, switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ClientTestService } from '@app/core/services/client-test.service';
import { ActivatedRoute } from '@angular/router';
import { OltUtility } from '@outerlimitstech/ngx-app-core';
import { BaseViewComponent, PrintService } from '@app/core';
import { ClientTest } from 'bgcslib';

@Component({
  templateUrl: './print-test.component.html',
  styleUrls: ['./print-test.component.scss']
})
export class PrintTestComponent extends BaseViewComponent implements OnInit {
  public clientContractTestId: number;
  public clientId: number;
  clientTest = new ClientTest();

  constructor(
    private service: ClientTestService,
    private printServie: PrintService,
    private route: ActivatedRoute
  ) { super(); }

  ngOnInit() {

    this.route.paramMap
      .pipe(map((params) => ({ clientId: OltUtility.toInt(params.get('clientId')), clientContractTestId: OltUtility.toInt(params.get('clientContractTestId')) })))
      .pipe(switchMap(params => {
        this.clientId = params.clientId;
        this.clientContractTestId = params.clientContractTestId;
        return this.service.get(this.clientId, this.clientContractTestId);
      }))
      .subscribe(json => {
        this.clientTest = new ClientTest(json);
        this.printServie.onDocumentReady();
        this.pageConfig.loading = false;
      });

  }



}

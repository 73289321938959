<h2 class="text-center">Test Outcome Report</h2>

<div class="row">
  <div class="col-6 text-muted mx-auto">
    <ul class="view-info">
      <li>
        <span class="view-label">Test:</span>
        <div class="view-data">
          {{ clientTest.test.name }}
        </div>
      </li>
      <li>
        <span class="view-label">Name:</span>
        <div class="view-data">
          {{ clientTest.clientName | formatName }}
        </div>
      </li>
      <li>
        <span class="view-label">Club:</span>
        <div class="view-data">
          {{ clientTest.clubName }}
        </div>
      </li>
    </ul>

  </div>

</div>

<div class="row">
  <div class="col-12">
    <app-client-test-view [clientTest]="clientTest"></app-client-test-view>
  </div>
</div>

<app-nav-bar></app-nav-bar>
<div class="nav-container">
  <div class="mr-auto">
    <lib-breadcrumbs></lib-breadcrumbs>
  </div>
</div>
<div class="container-fluid">
  <div [@routeAnimations]="getRouterOutletState(o)">
    <router-outlet #o="outlet"></router-outlet>
  </div>
</div>

<!-- <div [@routeAnimations]="getRouterOutletState(o)">
  <router-outlet #o="outlet"></router-outlet>
</div> -->
<!-- <router-outlet></router-outlet> -->


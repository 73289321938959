<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <button class="navbar-toggler navbar-toggler-right" type="button" (click)="toggleCollapse()" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand pr-4" [routerLink]="['/']">
    <img src="https://indianabgc.blob.core.windows.net/public/white-logo.png" height="40" alt="Logo" />
    Boys & Girls Clubs
  </a>

  <div class="collapse navbar-collapse" [class.show]="show">
    <ul class="navbar-nav mr-auto mt-2 mt-md-0">
      <li dropdown class="nav-item dropdown" placement="bottom right" routerLinkActive="active">
        <a class="nav-link" role="button" aria-haspopup="true" aria-expanded="false" href="javascript:void(0);"
          dropdownToggle>
          <em class="fas fa-users"></em> Clients <em class="fas fa-caret-down"></em>
        </a>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" [routerLink]="['/clients']">
            <em class="fas fa-search"></em> Search
          </a>
          <a class="dropdown-item" [routerLink]="['/clients/new']">
            <em class="fas fa-user-plus "></em> New Enrollment
          </a>
          <ng-container *ngIf="currentYear > 0">
            <a class="dropdown-item" [routerLink]="['/clients/reports', currentYear, 'service-matrix']"
              *ngxPermissionsExcept="['cloneHide']">
              <em class="fas fa-table"></em> Service Matrix
            </a>
            <a class="dropdown-item" [routerLink]="['/clients/reports', currentYear, 'test-matrix']">
              <em class="far fa-game-board-alt"></em> Test Matrix
            </a>
          </ng-container>
        </div>
      </li>
      <ng-container *ngxPermissionsExcept="['cloneHide']">
        <li dropdown class="nav-item dropdown" placement="bottom right" routerLinkActive="active"
          *ngxPermissionsOnly="['manage-users', 'system-maintenance']">
          <a class="nav-link" role="button" aria-haspopup="true" aria-expanded="false" href="javascript:void(0);"
            dropdownToggle>
            <em class="fas fa-print"></em> Reports <em class="fas fa-caret-down"></em>
          </a>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" [routerLink]="['/reports/billing']">
              <em class="fas fa-file-invoice-dollar"></em> Billing
            </a>
            <!-- <a class="dropdown-item" [routerLink]="['/reports/general']">
              <em class="fas fa-file-invoice-dollar"></em> General
            </a> -->
          </div>
        </li>
      </ng-container>

      <li dropdown class="nav-item dropdown" placement="bottom right" routerLinkActive="active"
        *ngxPermissionsOnly="['manage-users', 'system-maintenance', 'system-support']">
        <a class="nav-link" role="button" aria-haspopup="true" aria-expanded="false" href="javascript:void(0);"
          dropdownToggle>
          <em class="fas fa-cogs"></em> Admin <em class="fas fa-caret-down"></em>
        </a>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" routerLink="/admin/user-management">
            <em class="fas fa-users-cog"></em> User Management
          </a>
          <a class="dropdown-item" routerLink="/admin/club-management">
            <em class="fas fa-door-open"></em> Clubs
          </a>
        <a class="dropdown-item" routerLink="/admin/support/dashboard"  *ngxPermissionsOnly="['system-support']">
          <i class="fa-sharp fa-solid fa-gear-complex-code"></i> Support Dashboard
        </a>
        </div>
      </li>
    </ul>

    <ul class="navbar-nav  my-2 my-lg-0">
      <li dropdown class="nav-item dropdown" placement="bottom right" routerLinkActive="active"
        [class.bg-warning]="isImpersonating">
        <a class="nav-link" role="button" aria-haspopup="true" aria-expanded="false" href="javascript:void(0);"
          dropdownToggle>
          <em class="fa fa-user"></em> {{fullName}} <em class="fas fa-caret-down"></em>
        </a>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <button class="dropdown-item" (click)="stopImpersonating()" *ngIf="isImpersonating">
            <i class="fas fa-hand-paper"></i> Stop Impersonating
          </button>
          <a class="dropdown-item" [routerLink]="['/change-password']"><em class="fas fa-key"></em> Change Password</a>
          <a class="dropdown-item" [routerLink]="['/account/logout']"><em class="far fa-sign-out"></em> Logout</a>
        </div>
      </li>
    </ul>
  </div>
</nav>

<form [formGroup]="changePasswordForm" (ngSubmit)="save()" novalidate>


  <div class="row">
    <div class="col-12 col-lg-6 col-xl-5 mx-auto">
      <div class="card">
        <div class="card-header bg-dark text-white h5">
          <i class="far fa-lock"></i> Change Password
        </div>

        <div class="card-body">
          <div class="row">


            <div class="col-xs-12 col-md-12">
              <olt-form-group>
                <span class="control-label">Old Password</span>
                <input type="password" class="form-control" maxlength="50" formControlName="oldPassword">
              </olt-form-group>
            </div>
            <div class="col-xs-12 col-md-12">
              <olt-form-group>
                <span class="control-label">New Password</span>
                <input type="password" class="form-control" maxlength="50" formControlName="newPassword">
              </olt-form-group>
            </div>
            <div class="col-xs-12 col-md-12 mb-2">
              <app-password-strength></app-password-strength>
            </div>
            <div class="col-xs-12 col-md-12">
              <olt-form-group>
                <span class="control-label">Confirm Password</span>
                <input type="password" class="form-control" maxlength="50" formControlName="confirmPassword">
              </olt-form-group>
            </div>
          </div>
        </div>
        <div class="card-footer bg-light">
          <div class="row">
            <div class="col-12 action-buttons text-right">
              <button class="btn btn-primary" type="submit" (click)="save()" [oltWorkingButton]="pageConfig.working">
                <i class="far fa-lock"></i>
                Change Password
              </button>
              <a class="btn btn-secondary" [oltWorkingButton]="pageConfig.working" routerLink="/">
                <i class="fas fa-ban"></i> Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>



</form>

import { Component, OnInit } from '@angular/core';
import { BaseViewComponent, AuthService, CustomValidators } from '@app/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { OltHelperService } from '@outerlimitstech/ngx-app-core';
import { Router } from '@angular/router';

@Component({
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BaseViewComponent implements OnInit {
  public changePasswordForm: UntypedFormGroup;
  constructor(
    private helperService: OltHelperService,
    private authenticationService: AuthService,
    private router: Router,
    private fb: UntypedFormBuilder) { super(); }

  ngOnInit() {
    this.pageConfig.working = false;
    this.createForm();
  }

  private createForm() {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(50),
        CustomValidators.PasswordStrengthValidator]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
    }, {
      validator: CustomValidators.Match('newPassword', 'confirmPassword', 'does not match New Password')
    });
  }

  save() {
    if (!this.changePasswordForm.valid) {
      this.changePasswordForm.markAsDirty();
      this.changePasswordForm.markAsTouched();
      this.changePasswordForm.markAllAsTouched();
      return;
    }

    this.pageConfig.working = true;

    this.authenticationService.changePassword(this.changePasswordForm.value).subscribe(response => {
      if (response) {
        this.router.navigate(['/']);
        this.helperService.growlSuccessMessage('Password Changed Successfully');
      }
    }, err => {
      this.helperService.growlErrorMessage(err.error.message);
      this.changePasswordForm.get('oldPassword').setValue(null);
      this.pageConfig.working = false;
    });
  }

}

<!-- <table>
  <thead>
    <tr>
      <td>
        Test Here
        <div class="header-space"></div>
      </td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <div class="content">
          <router-outlet></router-outlet>
        </div>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td>
        <div class="footer-space"></div>
      </td>
    </tr>
  </tfoot>
</table> -->

<h1 class="header">
  Indiana Boys & Girls Club Alliance
</h1>
<router-outlet></router-outlet>
<h4 class="footer">
  Generated on {{ today | date:'medium' }}
</h4>

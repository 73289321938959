import { ConfirmModal, ModalTypeEnum } from '@outerlimitstech/ngx-app-core';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/services';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OltModalService } from '@outerlimitstech/ngx-bootstrap';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  providers: [OltModalService]
})
export class NavBarComponent implements OnInit {
  show = false;

  constructor(
    private authService: AuthService,
    private modalService: OltModalService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  get fullName(): string {
    if (this.authService.isAuthenticated) {
      return this.authService.fullName;
    }
    return null;
    // return 'Chris Straw';
  }

  get currentYear(): number | null {
    return this.authService.identity?.currentYear;
  }

  toggleCollapse() {
    this.show = !this.show;
  }

  get isImpersonating(): boolean {
    return this.authService.isImpersonating;
  }

  stopImpersonating(): void {
    this.modalService.showConfirm(
      new ConfirmModal(`Are you sure you want to stop impersonating ${this.fullName}?`, 'Impersonate User', ModalTypeEnum.Danger))
      .pipe(filter(response => response === true))
      .subscribe(json => {
        if (this.authService.stopImpersonating()) {
          this.router.navigateByUrl('/').then(() => {
            window.location.reload();
          });
        }
      });
  }
}


  
  <div class="app flex-row align-items-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="card">
              <h4 class="card-header bg-primary text-white">
                <img src="/assets/white-logo.png" height="40" alt="Logo" /> Indiana Alliance of Boys & Girls Clubs
              </h4>
              <div class="card-body">
                <h5 class="card-text">You do not have permissions to the requested application resource.</h5>
                <h5 class="card-text">If you believe you should have access to this resource, please email support.</h5>
                <h5 class="mb-5"><a [href]="'mailto:' + supportEmail">{{ supportEmail }}</a></h5>
                <h4 class="text-center"><a routerLink="/">Click Here</a> to return to the system</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
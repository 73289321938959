import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullLayoutComponent } from './core/views/full-layout/full-layout.component';
import { AuthGuard } from '@app/core/guard/authorized.guard';
import { NoauthLayoutComponent } from './core/views/noauth-layout/noauth-layout.component';
import { PermissionDeniedComponent } from './core/views/permission-denied/permission-denied.component';
import { ApplicationErrorComponent } from './core/views/application-error/application-error.component';
import { ChangePasswordComponent } from './core/views/change-password/change-password.component';
import { PrintLayoutComponent } from './core/views/print-layout/print-layout.component';
import { PrintTestComponent } from './core/views/print-layout/print-test/print-test.component';
import { ngxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'clients',
    pathMatch: 'full'
  },
  {
    path: 'permission-denied',
    component: PermissionDeniedComponent,
  },
  {
    path: 'access-denied',
    component: PermissionDeniedComponent,
  },
  {
    path: 'application-error',
    component: ApplicationErrorComponent,
  },
  {
    path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'test/:clientId/:clientContractTestId', component: PrintTestComponent }
    ]
  },
  {
    path: '',
    component: NoauthLayoutComponent,
    data: {
      title: 'Indiana Boys and Girls Club Alliance'
    },
    children: [
      {
        path: 'account',
        loadChildren: () => import('./user-account/user-account.module').then(m => m.UserAccountModule)
      },
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'clients',
        pathMatch: 'full'
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumbs: 'Change Password',
          title: 'Change Password'
        }
      },
      {
        path: 'clients',
        canActivate: [AuthGuard],
        data: {
          breadcrumbs: 'Clients',
        },
        loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
      },
      {
        path: 'reports',
        canActivate: [ngxPermissionsGuard],
        data: {
          breadcrumbs: 'Reports',
          permissions: {
            only: ['manage-users', 'system-maintenance'],
            redirectTo: 'clients'
          }
        },
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'admin',
        canActivate: [ngxPermissionsGuard],
        data: {
          breadcrumbs: 'Admin',
          permissions: {
            only: ['manage-users', 'system-maintenance'],
            redirectTo: 'clients'
          }
        },
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/clients',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { AuthService, CONSTANTS } from '@app/core';

@Component({
  selector: 'app-permission-denied',
  templateUrl: './permission-denied.component.html',
  styleUrls: ['./permission-denied.component.scss']
})
export class PermissionDeniedComponent implements OnInit {
  supportEmail = CONSTANTS.Application.SupportEmail;

   constructor(
    // private authService: AuthService,
  ) { }

  ngOnInit() {
    // this.authService.clearStorage();
  }

}

<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <h4 class="card-header bg-primary text-white">
            <img src="/assets/white-logo.png" height="40" alt="Logo" /> Indiana Alliance of Boys & Girls Clubs
          </h4>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-xl-12 text-center">
                <i class="text-danger fas fa-times-hexagon fa-5x"></i>
                <h1>An Application Error Occurred</h1>
              </div>
            </div>
            <h5 class="card-text">An error occurred. Close the browser and re-open the application.</h5>
            <h5 class="card-text">If the error continues, please email support.</h5>
            <h5 class="mb-5"><a [href]="'mailto:' + supportEmail">{{ supportEmail }}</a></h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

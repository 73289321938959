import { Component, OnInit } from '@angular/core';
import { CONSTANTS } from '@app/core/models';

@Component({
  selector: 'app-application-error',
  templateUrl: './application-error.component.html',
  styleUrls: ['./application-error.component.scss']
})
export class ApplicationErrorComponent implements OnInit {
  supportEmail = CONSTANTS.Application.SupportEmail;

  constructor() { }

  ngOnInit() {
  }

}

import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { stepper } from '@app/core/animations';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
  animations: [stepper]
})
export class FullLayoutComponent implements AfterViewInit {

  constructor(private changeRef: ChangeDetectorRef) {  }

  // Adding AfterViewInit fixed the error on the animation, but feels like a hack.
  // I'm not 100% sure why it's throwing the error after the Angular 15 upgrade
  // https://github.com/angular/angular/issues/36173
  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }
  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
}
